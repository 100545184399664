<template>
  <TTView>
    <VRow>
      <VCol>
        <h1 class="tt-text-headline-1">
          TTAvatar
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <h2 class="tt-text-headline-2">
          Есть аватар
        </h2>
      </VCol>
      <VCol>
        <TTAvatar
          :src="avatar.src"
          x-large
        />
      </VCol>
      <VCol>
        <TTAvatar
          :src="avatar.src"
          large
        />
      </VCol>
      <VCol>
        <TTAvatar
          medium
          :src="avatar.src"
        />
      </VCol>
      <VCol>
        <TTAvatar
          :src="avatar.src"
        />
      </VCol>
      <VCol>
        <TTAvatar
          :src="avatar.src"
          small
        />
      </VCol>
      <VCol>
        <TTAvatar
          :src="avatar.src"
          x-small
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <h2 class="tt-text-headline-2">
          Нет аватара, есть имя и фамилия
        </h2>
      </VCol>
      <VCol>
        <TTAvatar
          :text="avatar.text"
          x-large
        />
      </VCol>
      <VCol>
        <TTAvatar
          :text="avatar.text"
          large
        />
      </VCol>
      <VCol>
        <TTAvatar
          :text="avatar.text"
          medium
        />
      </VCol>
      <VCol>
        <TTAvatar
          :text="avatar.text"
        />
      </VCol>
      <VCol>
        <TTAvatar
          :text="avatar.text"
          small
        />
      </VCol>
      <VCol>
        <TTAvatar
          :text="avatar.text"
          x-small
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <h2 class="tt-text-headline-2">
          Нет аватара, имени и фамилии
        </h2>
      </VCol>
      <VCol>
        <TTAvatar
          x-large
        />
      </VCol>
      <VCol>
        <TTAvatar
          large
        />
      </VCol>
      <VCol>
        <TTAvatar
          medium
        />
      </VCol>
      <VCol>
        <TTAvatar />
      </VCol>
      <VCol>
        <TTAvatar
          small
        />
      </VCol>
      <VCol>
        <TTAvatar
          x-small
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <h2 class="tt-text-headline-2">
          Группы
        </h2>
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatars"
        />
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatars"
          small
        />
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatars"
          x-small
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <h2 class="tt-text-headline-2">
          Группы минимальные
        </h2>
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatarsMin"
        />
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatarsMin"
          small
        />
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatarsMin"
          x-small
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <h2 class="tt-text-headline-2">
          Группы равные ограничению
        </h2>
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatarsEqual"
        />
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatarsEqual"
          small
        />
      </VCol>
      <VCol cols="3">
        <TTAvatarGroup
          :items="avatarsEqual"
          x-small
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TTAvatar from '@uikit/components/TTAvatar/TTAvatar.vue';
import TTAvatarGroup from '@uikit/components/TTAvatarGroup/TTAvatarGroup.vue';

import avatar from '@/assets/users/ava1.png';

export default {
  name: 'Avatar',
  components: {
    TTAvatar,
    TTAvatarGroup,
  },
  data() {
    return {
      avatar: {
        src: avatar,
        text: 'WM',
      },
      avatars: [
        { src: avatar, text: 'SW' },
        { src: null, text: 'LS' },
        { src: avatar, text: 'DV' },
        { src: avatar, text: 'OK' },
        { src: avatar, text: 'HS' },
      ],
      avatarsMin: [
        { src: avatar, text: 'VV' },
        { src: null, text: 'JW' },
      ],
      avatarsEqual: [
        { src: avatar, text: 'MW' },
        { src: null, text: 'BC' },
        { src: null, text: null },
      ],
    };
  },
};
</script>
